import { Container } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { paperBackground } from './layout.module.css';
import NavBar from './navbar';

const Layout = ({ pageTitle, pageDescription, children, size }) => {
  return (
    <Container maxWidth={size ? size : 'lg'} sx={{ height: '100%', paddingBottom: '60px' }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <body class={paperBackground} />
      </Helmet>
      <NavBar />
      <main style={{ paddingTop: '16px' }}>{children}</main>
    </Container>
  );
};

export default Layout;
