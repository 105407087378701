import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';
import * as React from 'react';
import { appBar, navContainer, toolBar } from './navbar.module.css';

export default function NavBar() {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '48px' }}>
      <Typography variant="h3" fontFamily="Permanent Marker" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
        Ether Magellan
      </Typography>
      <AppBar position="static" elevation={0} classes={{ root: appBar }}>
        <Toolbar className={toolBar}>
          <div className={navContainer}>
            <Button LinkComponent={Link} to="/" color="inherit">
              <Typography variant="h6">ABOUT</Typography>
            </Button>
            <Button LinkComponent={Link} to="/projects" color="inherit">
              <Typography variant="h6">PROJECTS</Typography>
            </Button>
            <Button LinkComponent={Link} to="/bots" color="inherit">
              <Typography variant="h6">NFT BOTS</Typography>
            </Button>
            <Button LinkComponent={Link} to="/wikitrivia" color="inherit">
              <Typography variant="h6">WIKITRIVIA</Typography>
            </Button>
            <Button LinkComponent={Link} to="/art" color="inherit">
              <Typography variant="h6">ART</Typography>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
